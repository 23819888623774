import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import withTranslate from '../hoc/withTranslate';
import Button from '../../components/button';
import "./discussion-page3.scss"
import history from '../../routes/history';
import InputField from '../../components/input-field';
import success from "../../assets/images/success.svg";
import Slide from '@material-ui/core/Slide';
import * as authoringDispatcher from '../../actions/authoringDispatcher';
import Loader from '../../components/loader';
import ModelResponse from '../../components/model-response';

export class DiscussionPage3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      open: false,
      isLoading: false,
      html: props.formData.html,
      smartLink: props.formData.formData.smartLink
    }
  }

  onPreview = () => {
    let { formData, actions } = this.props;
    // if (formData.html) {
    //   history.push('/discussion-preview')
    // } else {
    this.setState({ isLoading: true });
    actions.generateHTML(formData.formData);
    // }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.formData.html && !state.html) {
      // console.log(' props: ', props, ' state:', state);  
      return { html: props.formData.html }
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('prevProps', prevProps.formData, ' props', this.props.formData, ' state:', this.state.html);
    if (this.props.formData.isHTMLGnrtnSuccessfull && !prevProps.formData.isHTMLGnrtnSuccessfull) {
      this.setState({ html: this.props.formData.html, isLoading: false });
      history.push('/discussion-preview')
    }
  }

  onList = () => {
    this.props.actions.resetTableRows();
    history.push('/')
  }

  copyCodeToClipboard = () => {
    const el = this.url
    el.select()
    document.execCommand("copy")
    this.setState({ copySuccess: true })
  }
  
  saveSmartLink = () => {
	const {actions: {saveSmartLink}, token} = this.props;
	saveSmartLink({discussionUrn: this.props.formData.formData.discussionUrn, smartLink: this.state.smartLink}, token);  
  }
  
  onSmartlinkChange = (event) => {
	  this.setState({smartLink: event.target.value});
  }

  onCloseSLSnackBar = () => {
    this.props.actions.closeSmartLinkSnackBar();
  }
  
  render() {
    const handleClose = (event, reason) => {

      this.setState({ open: false });
    };
    const { formData: { discussionUrn } } = this.props.formData;
    const { isSmartLinkSavedSuccessfully } = this.props;
    console.log("isSmartLinkSavedSuccessfully", isSmartLinkSavedSuccessfully);
    return (
      <div className="discussionURL">
        <div className="discussionURL_container">
          <Snackbar
            TransitionComponent={Slide}
            open={this.state.open}
            autoHideDuration={2000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <div onClose={handleClose} severity="success" className="successmsg">
              <img src={success} alt="success" />
              URL copied successfully!!
            </div>
          </Snackbar>
          <div className="discussionURL_row">
            <div className="discussionURL_id">
              <div className="discussion-id">
                <label className="input-box__label sim">Discussion ID</label>
                <p className="smallinput">{discussionUrn}</p>
              </div>
            </div>
            <div className="discussionURL_input">
              <div className="MuiGrid-root input-box input undefined">
                <label className="input-box__label">URL</label>
                <div className="input-box__field--container">
                  <input className="input-box__field url_input" ref={(url) => this.url = url} type="text" value={this.props.cdnUrl} />
                </div>
              </div>
              <div className="buttons">
                <Button
                  id="copyurl"
                  onClick={() => this.copyCodeToClipboard()}
                  type="button" text={'COPY URL'} />
              </div>
            </div>
            <div className="discussionURL_input">
            <div className="MuiGrid-root input-box input undefined">
              <label className="input-box__label">Smart Link</label>
              <div className="input-box__field--container">
                <input className="input-box__field url_input2" type="text" value={this.state.smartLink} onChange={(e) => this.onSmartlinkChange(e)} />
              </div>
            </div>
            <div className="buttons">
              <Button
                id="copyurl"
                onClick={() => this.saveSmartLink()}
                type="button" text={'Save'} />
            </div>
          </div>
          </div>
          <div className="listinglink">
            <Button id="list" onClick={this.onList} type="button" className="grey-outline" text={'DISCUSSION LIST'} />
            <Button id="preview" onClick={this.onPreview} type="button" className="grey-outline" text={'PREVIEW'} />

          </div>
          { isSmartLinkSavedSuccessfully && 
              <Snackbar
              TransitionComponent={Slide}
              open={isSmartLinkSavedSuccessfully}
              autoHideDuration={2000}
              onClose={this.onCloseSLSnackBar}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <div onClose={this.onCloseSLSnackBar} severity="success" className="successmsg">
                <img src={success} alt="success" />
                Smart Link saved successfully!!
              </div>
            </Snackbar>          
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  formData: state.discussion,
  cdnUrl: state.discussion.cdnUrl,
  token: state.authStore.sso ? state.authStore.sso.tokenId : null,
  isSmartLinkSavedSuccessfully: state.discussion.isSmartLinkSavedSuccessfully
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...authoringDispatcher }, dispatch)
  };
}

export const DiscussionPage3Component = withTranslate(DiscussionPage3);

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(DiscussionPage3));
