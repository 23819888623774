import React from 'react';
import { string, node } from 'prop-types';
import './typography.scss'

const Typography = (props) => {
  const { tag, children, className } = props;

  switch (tag) {
    case 'h1':
      return <h1 className={ className }>{children}</h1>;
    case 'h2':
      return <h2 className={ className }>{children}</h2>;
    case 'h3':
      return <h3 className={ className }>{children}</h3>;
    case 'h4':
      return <h4 className={ className }>{children}</h4>;
    case 'h5':
      return <h5 className={ className }>{children}</h5>;
    case 'h6':
      return <h6 className={ className }>{children}</h6>;
    case 'span':
      return <span className={ className }>{children}</span>;
    case 'label':
      return <label className={ className }>{children}</label>;
    default:
      return <p className={ className }>{children}</p>;
  }
};

Typography.propTypes = {
  tag: string,
  children: node,
  className: string,
};

Typography.defaultProps = {
  className: 'typography',
};

export default Typography;