const cache = new Map();

export class StorageManager {

  static setLocal(key, data) {
    const value = typeof data === "string" ? data : JSON.stringify(data);

    localStorage.setItem(key, value);
  }

  static getCache(key) {
    return cache.get(key);
  }

  static setCache(key, data) {
    return cache.set(key, data);
  }

  static getLocal(key, parse) {
    const data = localStorage.getItem(key);

    return parse ? JSON.parse(data) : data;
  }

  static removeLocal(key) {
    localStorage.removeItem(key);
  }

  static setSession(key, data) {
    sessionStorage.setItem(key, data);
  }

  static getSession(key) {
    const data = sessionStorage.getItem(key);

    return JSON.parse(data);
  }

  static removeSession(key) {
    sessionStorage.removeItem(key);
  }

  static clearAll() {
    localStorage.clear();
    sessionStorage.clear();
  }

}