import axios from 'axios';

export default class AuthenticationService {

    getConfig = () => {
      return axios.get('/api/config');
    }

    redirect = (url) => {
      setTimeout(() => {
        window.location.href = url;
      }, 1000)
    }
    

    logout = () => {
      const ssoCookie = this.getCookie('myCloudProxySession');
      console.log('ssoCookie', ssoCookie);
      const headers = {mycloudproxysession: ssoCookie};
      return axios.post('/api/token/logout', null,{headers});
    }

    isAuthenticated = () => {
      const ssoCookie = this.getCookie('myCloudProxySession');
      console.log('ssoCookie', ssoCookie);
      return (ssoCookie != null);
    }

    checkTokenValidity = () => {
      const ssoCookie = this.getCookie('myCloudProxySession');
      console.log('ssoCookie', ssoCookie);
      const headers = {mycloudproxysession: ssoCookie};
      return axios.get('/api/token/validity', {headers});
    }

    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0)==' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
    }   
    
    getLineOfBusiness = () => {
    	const ssoCookie = this.getCookie('myCloudProxySession');
    	const headers = {mycloudproxysession: ssoCookie};
    	return axios.get('/api/user/lineOfBusiness', {headers});
    }
}
