import React from 'react';
import { Redirect } from 'react-router-dom';
import DiscussionForm from '../views/discussion-form/discussion-form'
import DiscussionPreview from '../views/discussion-preview/discussion-preview';
import DiscussionPage3 from '../views/discussion-page3/discussion-page3'
import DiscussionList from '../views/discussion-list/discussion-list';

export const publicRoutes = [
  {
    component: DiscussionList,
    exact: true,
    path: '/',
    title: 'Discussion List',
  },
  {
    component: DiscussionForm,
    exact: true,
    path: '/discussion-form',
    title: 'Discussion Form',
  },{
    component: DiscussionPreview,
    exact: true,
    path: '/discussion-preview',
    title: 'Discussion Preview',
  },{
    component: DiscussionPage3,
    exact: true,
    path: '/discussion-url',
    title: 'Discussion URL',
  }
];
