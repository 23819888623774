import React, { PureComponent } from 'react';
import { interpolate, trans } from '../../utils';

const withTranslate = (Component) => {
  return class extends PureComponent {
    render(){
      return (<Component interpolate={ interpolate } trans={ trans }  { ...this.props } />);
    }
  }
}

export default withTranslate;