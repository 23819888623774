import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import './loader.scss';

const Loader = (props) => {
const box = [
  {
    stage: 1
  },{
    stage: 2
  },{
    stage: 3
  },{
    stage: 4
  },{
    stage: 5
  }
]

  const renderBox = () => {
    return box.map((item, key) => {
      return (
        <span key={ key } className={`box`}></span>
      )
    }) 
  }

  return (
    <div className="loader-container">
      <Dialog
        open={ true }
        PaperProps = { { className: 'loader-main-container' } }
        className = "dialog-root"
      >
        <div className = "loader-items-container">
          <div className = "dialog-description">
            <p className = "loader-description-items">Please wait, while we load content..</p>
          </div>
          <div className="loader-box">
           {renderBox()}
          </div>
        </div>
      </Dialog>
    </div>
  )
};
export default Loader;
