import { ACTION_TYPES } from '../actions/actionTypes';
import { updateObject } from '../utils';

export const initialState = {
    sso: null,
    lineOfBusinesses: []
}

const authStore = (state = initialState, { type, payload }) => {
    switch (type) {
        case ACTION_TYPES.SET_SSO_TOKEN:
            return updateObject(state, {sso: {tokenId: payload}});
        case ACTION_TYPES.SET_LINE_OF_BUSINESS:
        	return updateObject(state, {lineOfBusinesses: [...payload.roles]});
        default:
            return {...state }            
    }
}

export default authStore