import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withTranslate from '../hoc/withTranslate';
import Button from '../../components/button';
import "./discussion-preview.scss"
import history from '../../routes/history';
import * as authoringDispatcher from '../../actions/authoringDispatcher';
import Loader from '../../components/loader';

export class DiscussionPreview extends Component {

  state = {
    isSuccessfull: false,
    error: null, 
    isLoading: false
  }

  onEdit = () => {
    history.push('/discussion-form')
  }

  onCancel = () => {
    history.push('/discussion-form')
  }

  onSubmit = () => {
    const {actions, formData, token} = this.props;
    this.setState({isLoading: true, isSuccessfull: false}, () => {
      actions.resetFlag();
      //Submit only if there is no discussionUrn, otherwise save
      if (formData.formData.discussionUrn && formData.formData.discussionUrn.length >= 0) {
        actions.saveDiscussion(formData.formData, token);
      } else {
        actions.submitDiscussion(formData.formData, token);
      }
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.error && prevState.error) {
      return {isSuccessfull: false, error: nextProps.error}
    } else if(nextProps.cdnUrl && !prevState.cdnUrl) {
      return { cdnUrl: nextProps.cdnUrl};
    } else if (nextProps.formData && nextProps.formData.html){
      return { isLoading: false};
    }
    return null 
  }
  componentDidUpdate(prevProps, prevState){
    if (!this.state.isSuccessfull && this.props.isSuccessfull) {
      if(this.state.isLoading){
        this.setState({ isLoading: false });
      }
      history.push('/discussion-url')
    } else if (!this.state.error && this.props.error) {
      this.setState({error: this.props.error})
    } else if (!prevProps.cdnUrl && this.props.cdnUrl) {
      this.setState({ isLoading: false});
      history.push('/discussion-url')
    } else if (!prevProps.formData.html && this.props.formData.html) {
      this.setState({isLoading: false})
    }
  }

  render(){
    const {error} = this.props;
    if (this.state.isLoading) return <Loader />
    return ( 
      <Fragment>
            {error && <div className="errormsg">{error}</div>}
            <div className="discussion-preview">
              <div className="discussion-preview_container">
                <div className="discussion-preview_container_html">
                <div dangerouslySetInnerHTML={{ __html: this.props.formData.html }} />
                <div className="buttons">
                    <Button onClick = {this.onCancel} id="cancel-btn" type="button" text={ 'CANCEL' }  className="grey-bg-outline" />
                    <Button onClick = {this.onEdit} id="edit-btn" type="button" text={ 'EDIT' }  className="grey-outline" />
                    <Button onClick = {this.onSubmit} id="submit-btn" type="button" text={ 'SUBMIT' } />
                  </div>
                </div>
              </div>
            </div>
            
      </Fragment>
    );
  }
}



const mapStateToProps = (state) => ({
  formData: state.discussion,
  isSuccessfull: state.discussion.isSuccessfull,
  error: state.discussion.error,
  token: state.authStore.sso ? state.authStore.sso.tokenId : null,
  cdnUrl: state.discussion.cdnUrl
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...authoringDispatcher }, dispatch)
  };
}

export const DiscussionsPreviewComponent = withTranslate(DiscussionPreview);

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(DiscussionPreview));
