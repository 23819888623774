import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { TablePagination } from '@material-ui/core';
import './data-table.scss';

const DataTable = (props) => {
  const { headers, tableRows } = props;

  return (
    <div className="table-container">
      <MaterialTable
        columns={headers}
        data={tableRows}
        options={{
          toolbar: false,
          showFirstLastPageButtons: false,
          pageSize: 10,
          paginationType: 'stepped'
        }}
        icons={
          {
            NextPage: forwardRef((props, ref) => <NavigateNextIcon className="page-icon" {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <NavigateBeforeIcon className="page-icon" {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => {
              return <ExpandLessIcon {...props} ref={ref} />
            })
          }
        }
        
        components={{
          Pagination: (props) => {
            return (
              <>
                <td className="bottom-text">
                Viewing {props.rowsPerPage} Discussion List
                </td>
                <TablePagination
                  {...props}                  
                />
              </>
            )
          },
        }}

      />
    </div>
  );
};

export default DataTable;
