import React from 'react';
import Interpolate from 'react-interpolate-component';
import { StorageManager } from './storage-manager';
import { LANGUAGE_PACK, LOCALE } from './constants';

export const getLocale = () => {
  return StorageManager.getLocal(LOCALE) || 'en';
};

const getLanguegPck = (selectedLanguge) => {
  const arr = StorageManager.getCache(LANGUAGE_PACK);

  if (!arr) {
    return ;
  }

  const arrLangPack = arr.languagePack;

  // filter selected language pack
  const filterSelectedLanguagePack = arrLangPack.find( (lang) => lang.locales.includes(selectedLanguge));

  // eslint-disable-next-line consistent-return
  return filterSelectedLanguagePack ? filterSelectedLanguagePack.text : "";

}

export const trans = (str) => {
  if (str === undefined || typeof (str) !== 'string') {
    return 'key is not string';
  }

  const loc = getLocale();
  const langePack = getLanguegPck(loc);

  if(!langePack || !langePack[str]){
    return str;
  }

  return langePack[str];
};

export const interpolate = (str, dvalues) => {
  if (str === undefined || typeof (str) !== 'string') {
    return 'key is not string';
  }

  const loc = getLocale();

  const langePack = getLanguegPck(loc);

  if(!langePack || !langePack[str]){
    return  <Interpolate { ...dvalues } format={ str } />;
  }

  return <Interpolate { ...dvalues } format={ langePack[str] } />;
};

// eslint-disable-next-line func-names
export const createAction = (type, ...argNames) => function (...args) {
  const action = { type };

  argNames.forEach((_arg, index) => {
    action[argNames[index]] = args[index];
  });
  return action;
}

export const updateObject = (initialObject, newParams) => {
  return {
    ...initialObject,
    ...newParams
  }
}

export const covertUnit = (number) => {
  return (number < 10 ? '0' : '') + number

}

export const makeFirstLetterCap = (string) => {
  if(!string){
    throw new Error("Please enter a string");
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}
