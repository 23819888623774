import React from "react";
import "./search-input.scss";
import Search from "@material-ui/icons/Search";

const SearchInput = (props) => {
  const { onSearchChange, placeholder, config } = props;

  const handleSearchChange = (e) => {
    onSearchChange(e.target.value);
  };

  return (
    <div className="search-bar-box">
      <span className="input-container">
        <input
          id="input-search"
          placeholder={ placeholder }
          onChange={ handleSearchChange }
          { ...config }
        />
        <Search />
      </span>
    </div>
  );
};

export default React.memo(SearchInput);
