import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { bool, node, object } from "prop-types";
import './layout.scss'
import Header from '../header';

const Layout = (props) => {
  const { children } = props;
  return (
    <div className="wrapper">
      <CssBaseline />
      <Header
        isAuth={ false }
        { ...props }
      />
      
      <main className="wrapper__content">
      { children }
      </main>
    </div>
  );
};

Layout.propTypes = {
  isAuth: bool,
  children: node,
  userProfile: object,
}

export default Layout;
