import React from 'react';
import { string, func, bool } from 'prop-types';
import './button.scss';

const Button = (props) => {
  const {
    type, className, text, onClick, disabled
  } = props;

  return (
    <input
      type={ type }
      className={ `button ${className}` }
      onClick={ onClick }
      value={ text }
      disabled={ disabled }
    />
  );
};

Button.propTypes = {
  className: string,
  onClick: func,
  text: string,
  type: string,
  disabled: bool
};

Button.defaultProps = {
  className: 'btn-primary',
  disabled: false,
  type: 'submit',
};

export default Button;
