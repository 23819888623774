import React from 'react';
import {
  Link
} from "react-router-dom";
import logo from '../../assets/images/PearsonLogo_Avatar.png';
import './logo.scss';
const Logo = () => (
  <Link to="/" className="logo_wrap">
    <div className="logo_wrap_logo">
      <img src={ logo } alt="HNO" />
      <span>Discussion Authoring</span>
    </div>
  </Link>
);

export default Logo;
