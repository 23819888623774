import React from 'react';
import { Grid } from '@material-ui/core';
import {
  func, node, string, object, bool
} from 'prop-types';
import './input-field.scss';
import Error from '../error'

const InputField = ({
  config,
  label,
  customClass,
  onChange,
  error,
  value,
  showContent,
  content,
  disabled,
  placeholder
}) => {

  return (
    <Grid className={`input-box ${customClass} ${error && 'input-box--error'}`}>
      {label
        &&
        <label className="input-box__label">
          {label}
        </label>
      }
      <div className="input-box__field--container">
        <input
          disabled={disabled}
          placeholder={placeholder}
          className={`input-box__field ${error && 'input-box__field--error'}`}
          onChange={onChange}
          value={value}
          {...config}
        />
        {showContent && content}
      </div>
      {error
        && <Error errorMessage={error} />
      }
    </Grid>
  );
};

InputField.propTypes = {
  value: string,
  config: object.isRequired,
  onChange: func.isRequired,
  label: string,
  error: string,
  showContent: bool,
  content: node
};

InputField.defaultProps = {
  config: { type: "text" }
};

export default InputField;
