import React, { useEffect } from "react";
import { Select, MenuItem } from "@material-ui/core";
import "./dropdown.scss";

const DropDown = (props) => {
  const {
    dropDown,
    selectedTypeLabel,
    isDisabled,
    label,
    onHandleChange
  } = props;

  const [ SelectedTypeLabel, setSelectedTypeLabel ] = React.useState(
    selectedTypeLabel
  );

  useEffect(() => {
    setSelectedTypeLabel(selectedTypeLabel);
  }, [ selectedTypeLabel ]);

  const handleChange = (event) => {
    setSelectedTypeLabel(event.target.value);
    onHandleChange(event.target.value);
  };

  return (
    <div className="dropdown-container">
      <label for={label} className="dropdown-container-label">{label}</label>

      <Select
        className="dropdown-container-inputbox"
        disabled={ isDisabled }
        disableUnderline={ true }
        id={label}
        onChange={ handleChange }
        value={ SelectedTypeLabel }
        classes={ { root: "dropdown-container-inputbox-input", disabled: "dd-disabled" } }
        MenuProps={ {
          classes: { paper: "dropdown-container-inputbox-nav" },
          variant: "menu"
        } }
      >
        {dropDown.map((item, index) => {
          return (
            <MenuItem key={ `dd-${index}` } name={ item.label } value={ item.type } classes={ { selected: 'dd-selected-menu' } }>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default React.memo(DropDown);
