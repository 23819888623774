import React from 'react';
import Logo from '../logo';
import './header.scss'
import Button from '../button';

const Header = (props) => {
  return(
    <div className="header">
      <Logo />
      <span><Button id="logout-btn" onClick={() => props.logout(props.config.mycloud.mycloudBaseUrl)} type="submit" text={'Logout'} /></span>
    </div>
  );
}
 

export default Header;
