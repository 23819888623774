import { ACTION_TYPES } from '../actions/actionTypes';
import { updateObject } from '../utils';

export const initialState = {
    formData: {discussionUrn: null},
    isLoading: true,
    html: null,
    urn: "",
    tableRows: [],
    error: null,
    isSuccessfull: false,
    cdnUrl: null,
    isHTMLGnrtnSuccessfull: false,
    isSmartLinkSavedSuccessfully: false
}

const authoringContentStore = (state = initialState, { type, payload }) => {
    switch (type) {
        case ACTION_TYPES.SAVE_FORM_DATA:
            return updateObject(state, { formData: payload, isSuccessfull: false, isHTMLGnrtnSuccessfull: false, isSmartLinkSavedSuccessfully: false })

        case ACTION_TYPES.IS_LOADING:
            return updateObject(state, { isLoading: payload })

        case ACTION_TYPES.SET_GENERATED_HTML:
            return updateObject(state, { html: payload, isHTMLGnrtnSuccessfull: true })

        case ACTION_TYPES.CREATE_URN:
            return updateObject(state, { urn: payload })
        
        case ACTION_TYPES.CLEAR_FORM_DATA:
            return updateObject(state, { formData: payload,  urn:"", cdnUrl: false, 
                    isSuccessfull: false, error: null, isHTMLGnrtnSuccessfull: false, isSmartLinkSavedSuccessfully: false })
        
        case ACTION_TYPES.RESET_FLAG:
            return updateObject(state, { isSuccessfull: false, error: null, isHTMLGnrtnSuccessfull: false, isSmartLinkSavedSuccessfully: false })

        case ACTION_TYPES.SET_DISCUSSION_LIST: 
            const data = payload && payload.data.map(item => ({...item, action: 'Edit'}))
            return updateObject(state, { discussionList: payload.data, tableRows: data});

        case ACTION_TYPES.DISCUSSION_SUBMITTED_SUCCESSFULL:
            return updateObject(state, { isSuccessfull: true, cdnUrl: payload.cdnUrl, isHTMLGnrtnSuccessfull: false, 
                    formData: {...state.formData, 
                                discussionUrn: payload.discussionUrn }});
        
        case ACTION_TYPES.UPDATE_DISCUSSION_LIST:
                const payloadRes = { ...state.formData, action:'Edit' }
                const currentTableRow = state.tableRows
                const newAddRow = [payloadRes].concat(currentTableRow)
                
            return updateObject(state, { tableRows: newAddRow});

        case ACTION_TYPES.SINGLE_DISCUSSION_UPDATE:
            return updateObject(state, { discussionList: {}, tableRows: {}});

        case ACTION_TYPES.DISCUSSION_SUBMITTED_FAILED:
        return updateObject(state, { isSuccessfull: false, error: payload, cdnUrl: null, isHTMLGnrtnSuccessfull: false, isSmartLinkSavedSuccessfully: false});
        
        case ACTION_TYPES.SMART_LINK_SAVED:
            let {formData} = state;
            formData.smartLink = payload.smartLink;
            return updateObject(state, {formData: {...formData}, isSmartLinkSavedSuccessfully: true})
        
        case ACTION_TYPES.RESET_SMART_LINK_SAVED:
            return updateObject(state, {isSmartLinkSavedSuccessfully: false})
        default:
            return {...state }
    }
}

export default authoringContentStore