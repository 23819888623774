import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withTranslate from '../hoc/withTranslate';
import Button from '../../components/button';
import * as authoringDispatcher from '../../actions/authoringDispatcher';
import * as authenticationDispatcher from '../../actions/authenticationDispatcher';
import DataTable from '../../components/data-table';
import history from '../../routes/history';
import SearchInput from '../../components/search-input';
import "./discussion-list.scss";
import Loader from '../../components/loader';


export class DiscussionList extends Component {

  state = {
    isLoading: true,
    sso: null,
    headers: [
      {
        title: 'Discussion ID',
        field: "discussionUrn",
        headerStyle: {
          fontSize: '16px',
          fontFamily: 'Open Sans, sans-serif'
        }
      },
      {
        title: 'Title',
        field: "title",
        headerStyle: {
          fontSize: '16px',
          fontFamily: 'Open Sans, sans-serif'
        }
      },
      {
        title: 'Section Title',
        field: "subTitle",
        headerStyle: {
          fontSize: '16px',
          fontFamily: 'Open Sans, sans-serif'
        }
      },
      {
          title: 'Smart Link',
          field: "smartLink",
          headerStyle: {
            fontSize: '16px',
            fontFamily: 'Open Sans, sans-serif'
          }
      },
      {
        title: 'Line of Business',
        field: "lineOfBusiness",
        headerStyle: {
          fontSize: '16px',
          fontFamily: 'Open Sans, sans-serif'
        },
        render: (data)=>{
          return this.props.lineOfBusinesses?.find(it => it.type === data.lineOfBusiness)?.label ?? '-';
        }
    },
      {
        title: 'Action',
        field: "action",
        sorting: false,
        render: (data) => {
          return <span onClick = { () => {
            const formData = {
              ...data,
            }
            this.props.actions.saveFormData(formData)
            history.push('/discussion-form')
          } } className="edit_button">Edit</span>
        }
      },
    ],
    filteredList: [],
    tableRows: []
  }

  componentDidMount() {
    // this.setState({ isLoading: false, filteredList: this.state.tableRows });
    const {sso, actions, lineOfBusinesses} = this.props;
    if (!sso) {
      console.log('TOKEN IS NOT AVAILABLE');
      this.setState({ isLoading: true});
      //actions.getSSOToken();
    } else {
      // console.log('always get the latest list of discussions === ', this.props);
      if((this.props.tableRows && this.props.tableRows.length > 0)){
        this.setState({ isLoading: false, filteredList: this.props.tableRows });
      } else if (this.props.lineOfBusinesses.length > 0){
        this.props.actions.getDiscussionList(sso.tokenId, this.props.lineOfBusinesses);
      }
      if (lineOfBusinesses && lineOfBusinesses.length == 0){ 
    	  actions.getAllLinesOfBusiness();
      }
    }
  }

   static getDerivedStateFromProps(nextProps, prevState) {
    //  console.log('prevState.filteredList', prevState.filteredList, ' nextProps.tableRows:', nextProps.tableRows);
     if (!prevState.sso && nextProps.sso || (prevState.sso != nextProps.sso)) {
       return {sso: nextProps.sso.tokenId};
     }else if (prevState.filteredList.length == 0 && nextProps.tableRows && nextProps.tableRows.length > 0) {
       return {tableRows: nextProps.tableRows};
     }else if (!prevState.isLoading && nextProps.tableRows && nextProps.tableRows.length > 0) {
       return {tableRows: nextProps.tableRows};
     }
     return null;
   }

  componentDidUpdate(prevProps, prevState) {
     
	if (prevProps.lineOfBusinesses.length == 0 && this.props.lineOfBusinesses.length == 0) {
	  this.props.actions.getAllLinesOfBusiness();
	} 
//	console.log('prevProps', prevProps.tableRows, ' this.props.lineOfBusinesses', this.props.sso, ' lineOfBusinesses:', this.props.lineOfBusinesses);
	if(this.props.lineOfBusinesses.length > 0 && this.props.sso && this.props.tableRows.length == 0) {
      this.props.actions.getDiscussionList(this.props.sso.tokenId, this.props.lineOfBusinesses);
    }else if ((!prevProps.tableRows || prevProps.tableRows.length == 0) && this.props.tableRows && this.props.tableRows.length > 0) {
      let {tableRows} = this.props
      this.setState({filteredList: tableRows, tableRows, isLoading: false});
    }else if (prevState.isLoading && this.props.tableRows && this.props.tableRows.length > 0) {
      let {tableRows} = this.props
      this.setState({filteredList: tableRows, tableRows, isLoading: false});
    }else if (!prevProps.tableRows && this.props.tableRows && this.props.tableRows.length === 0) {
      this.setState({isLoading: false});
    }
  }


  onSearchChange = (searchString) => {
    let updatedList = this.props.tableRows;
    updatedList = updatedList.filter(function (item) {
      return (
        (item.discussionUrn && item.discussionUrn.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
        (item.title && item.title.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
        (item.subTitle && item.subTitle.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
        (item.smartLink && item.smartLink.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
      );
    });
    this.setState({ filteredList: updatedList });
  }

  onCreateNew = () => {
    this.props.actions.clearFormData();
    history.push('/discussion-form')
  }

  render() {
    if (this.state.isLoading) return <Loader />
    return (
      <Fragment>
        <div className="discussion-list">
          <div className="discussion-list_container">
            <div className="discussion-list_container_title">
              <p className="title">Discussion List</p>
            </div>
            <div className="discussion-list_container_button">
              <SearchInput config={{ id: "search_input", name: "search_input" }} placeholder={`Search by Discussion ID or Title or Section Title or Smart Link`} onSearchChange={this.onSearchChange} />
              <Button id="create-btn" onClick={this.onCreateNew} type="submit" text={'CREATE NEW'} />
            </div>
            <div className="discussion-list_container_table">
              <DataTable headers={this.state.headers} tableRows={this.state.filteredList} />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  formData: state.discussion,
  sso: state.authStore.sso,
  tableRows: state.discussion.tableRows,
  lineOfBusinesses: state.authStore.lineOfBusinesses
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...authoringDispatcher, ...authenticationDispatcher }, dispatch)
  };
}

export const DiscussionListComponent = withTranslate(DiscussionList);

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(DiscussionList));
