/// unique action keys
export const ACTION_TYPES = {
    SAVE_FORM_DATA: 'SAVE_FORM_DATA',
    IS_LOADING:'IS_LOADING',
    SET_GENERATED_HTML: 'SET_GENERATED_HTML',
    CREATE_URN:'CREATE_URN',
    CLEAR_FORM_DATA:'CLEAR_FORM_DATA',
    SET_SSO_TOKEN: "SET_SSO_TOKEN",
    SET_DISCUSSION_LIST: "SET_DISCUSSION_LIST",
    DISCUSSION_LIST_FETCH_ERROR: "DISCUSSION_LIST_FETCH_ERROR",
    DISCUSSION_SUBMITTED_SUCCESSFULL: "DISCUSSION_SUBMITTED_SUCCESSFULL",
    DISCUSSION_SUBMITTED_FAILED: "DISCUSSION_SUBMITTED_FAILED",
    RESET_FLAG: "RESET_FLAG",
    UPDATE_DISCUSSION_LIST:'UPDATE_DISCUSSION_LIST',
    SINGLE_DISCUSSION_UPDATE:'SINGLE_DISCUSSION_UPDATE',
    SET_LINE_OF_BUSINESS: 'SET_LINE_OF_BUSINESS',
    SMART_LINK_SAVED: 'SMART_LINK_SAVED',
    RESET_SMART_LINK_SAVED: 'RESET_SMART_LINK_SAVED'
};
